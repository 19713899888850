<template>
    <div class="container need">
        <el-form :model="searchForm" ref="searchForm" label-width="120px" class="searchForm">
            <el-row>
                <el-col :span='6'>
                    <el-form-item label="需求名称">
                        <el-select filterable default-first-option :allow-create="true" clearable v-model="searchForm.needName" placeholder="" style="width:100%;">
                            <el-option v-for="(item,index) in dataObj.needNames" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='6'>
                    <el-form-item label="模块名称">
                        <el-select multiple filterable default-first-option :allow-create="true" clearable v-model="searchForm.moduleNameList" placeholder="" style="width:100%;">
                            <el-option v-for="(item,index) in dataObj.moduleNames" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='6'>
                    <el-form-item label="负责人">
                        <el-select multiple clearable v-model="searchForm.managerList" placeholder="请选择" style="width:100%;">
                            <el-option v-for="(item,index) in dataObj.managers" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='6'>
                    <el-form-item label="标签">
                        <el-select clearable v-model="searchForm.tags" placeholder="请选择" multiple style="width:100%;">
                            <el-option v-for="(item,index) in dataObj.tagList" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='6'>
                    <el-form-item label="版本号">
                        <el-select clearable v-model="searchForm.proVersion" placeholder="请选择" style="width:100%;">
                            <el-option v-for="(item,index) in dataObj.proVersions" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='6'>
                    <el-form-item label="进度">
                        <el-select v-model="searchForm.status" placeholder="请选择" style="width:100%;">
                            <el-option label="未开始" :value="0"></el-option>
                            <el-option label="进行中" :value="1"></el-option>
                            <el-option label="完成" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='6'>
                    <el-form-item label="摘要">
                        <el-input v-model="searchForm.brief" placeholder="" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span='6'>
                    <el-form-item label="开始时间">
                        <el-date-picker v-model="searchForm.beginTime" type="date" placeholder="选择日期时间" value-format="yyyy-MM-dd" style="width:100%;">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='6'>
                    <el-form-item label="优先级">
                        <el-select clearable v-model="searchForm.needPriorityList" placeholder="请选择" multiple style="width:100%;">
                            <el-option label="高" :value="0"></el-option>
                            <el-option label="中" :value="1"></el-option>
                            <el-option label="低" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="测试">
                        <selectSearch ref="selectSearch" style="width:100%;"></selectSearch>
                    </el-form-item>
                </el-col>
                <el-col :span='6'>
                    <el-form-item>
                        <el-button class="formBtn btn-reset" @click="resetForm">重置</el-button>
                        <el-button class="formBtn" @click="search(type=-1)" :loading="Searching">
                            {{Searching ?  '搜索中...':'搜索'}}
                        </el-button>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item>
                        <el-button class="formBtn" @click="openNewNeed(id=-1)">创建</el-button>
                        <el-button class="formBtn btn-reset" :disabled="checkList.length==0">导出</el-button>
                        <el-button class="formBtn btn-reset" :disabled="checkList.length==0" @click="delList()">批量删除</el-button>
                        <el-button class="formBtn btn-reset" :disabled="checkList.length==0" @click="transferAll()">批量转项</el-button>
                        <el-button class="formBtn btn-reset" :disabled="checkList.length==0" @click="forwardAll()">批量转发</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <createNeed @createNeed="handleNewNeed" :Member="proManager" ref="createNeed"></createNeed>

        <!-- 项目列表 -->
        <div>
            <!-- <el-table :data="apiList" @selection-change="handleSelectionChange" stripe v-loading="Searching" :row-style="{height:'64px',}" :cell-style="{'text-align':'center'}" :header-cell-style="{'border-top':'solid 1px #E3E3E3','background': '#F5F5F5','text-align':'center'}" style="width: 100%" > -->
            <el-table :data="apiList" @selection-change="handleSelectionChange" stripe v-loading="Searching" :row-style="{height:'64px',}" :cell-class-name="changeClass" :header-cell-style="{'border-top':'solid 1px #E3E3E3','background': '#F5F5F5','text-align':'left'}" style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div class="tableForm">
                            <div class="com">
                                {{props.row.brief}}
                            </div>
                            <div class="bom">
                                <div class="ri">
                                    <div style="margin-bottom:20px;">
                                        <el-radio-group v-model="props.row.needPriority" @change="setPercent($event,props.row)">
                                            <el-radio :label="0">高</el-radio>
                                            <el-radio :label="1">中</el-radio>
                                            <el-radio :label="2">低</el-radio>
                                        </el-radio-group>
                                    </div>
                                    <div style="display:flex;align-items: center;">
                                        <span style="width:130px;">开始时间：</span>
                                        <el-date-picker v-model="props.row.beginTime" type="date" placeholder="选择日期时间" value-format="yyyy-MM-dd" @change="setPercent($event,props.row)">
                                        </el-date-picker>
                                    </div>
                                    <el-slider v-model="apiList[props.$index].percent" input-size="large" :format-tooltip="formatTooltip" @change="setPercent($event,props.row)"></el-slider>
                                </div>
                                <div class="cen">
                                    <el-tag v-for="(tag,index) in apiList[props.$index].tags" :key="index" closable style="margin-right:5px;" @close="handleClose(index,props.$index,props.row)">
                                        {{tag}}
                                    </el-tag>
                                    <el-select style="width:100px" filterable default-first-option :allow-create="true" clearable v-if="apiList[props.$index].inputVisible" v-model="apiList[props.$index].tagNewName" placeholder="" @change="handleInputConfirm(props.$index,props.row)">
                                        <el-option v-for="(item,index) in dataObj.tagList" :key="index" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                    <img v-else src="../assets/add.png" alt="" @click="showInput(props.$index)">
                                </div>
                                <div class="le">
                                    <div class="value">
                                        <el-input v-model="props.row.needDesc" type="textarea" :rows="3" placeholder="请输入你的评论建议" size="mini"></el-input>
                                        <img src="../assets/sub.png" alt="" @click="comment(props.row)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="ID" width="120">
                    <template slot-scope="scope">
                        <div @click="details(scope.row.id)" style="cursor: pointer;" class="pointer">
                            {{scope.row.proVersion}}-{{scope.row.id}}
                            <span class="readIng" v-show="scope.row.readStatus!=1"></span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="needName" label="需求名称" min-width="150">
                    <template slot-scope="scope">
                        <!-- <el-link type="primary" :href='scope.row.pageUrl' icon="el-icon-link"> {{scope.row.needName}}</el-link>
                        <el-link @click="openNewWinTab(scope.row)"> {{scope.row.needName}}</el-link> -->
                        <div @click="details(scope.row.id)" style="cursor: pointer;" class="pointer">
                            {{scope.row.needName}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="进度" min-width="120">
                    <template slot-scope="scope">
                        <img src="../assets/status0.png" style="height:20px" alt="" v-if="scope.row.status==0">
                        <img src="../assets/status1.png" style="height:20px" alt="" v-if="scope.row.status==1">
                        <img src="../assets/status2.png" style="height:20px" alt="" v-if="scope.row.status==2">
                        <span>{{scope.row.percent}}%</span>
                    </template>
                </el-table-column>
                <el-table-column label="标签" min-width="180">
                    <template slot-scope="scope">
                        <div>
                            <el-tag v-for="(tag,index) in scope.row.tags" :key="index" style="margin-left:5px">
                                {{tag}}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="moduleName" label="模块" min-width="180">
                </el-table-column>
                <el-table-column prop="beginTime" label="起止时间" min-width="120" :formatter="dateFormat">
                </el-table-column>
                <el-table-column prop="manager" label="负责人" min-width="180">
                </el-table-column>
                <el-table-column label="操作" fixed="right" min-width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="topNeed(scope.row.id,'1')" v-if="!scope.row.top">置顶</el-button>
                        <el-button type="text" @click="topNeed(scope.row.id,'0')" v-if="scope.row.top">取消置顶</el-button>
                        <el-button type="text" @click="careNeed(scope.row.id,scope.row.care)" v-if="!scope.row.care">关注</el-button>
                        <el-button type="text" @click="careNeed(scope.row.id,scope.row.care)" v-if="scope.row.care">取消关注</el-button>
                        <!-- <el-button type="text" @click="details(scope.row.id)">查看</el-button> -->
                        <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
                        <el-button type="text" @click="copy(scope.row.id)">复制</el-button>
                        <el-button type="text" @click="deleteApi(scope.row.id)" class="delectBtn">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页器 -->
        <div class="pagination">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="searchForm.pageNumber" :page-sizes="[5, 10, 15]" :page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
            </el-pagination>
        </div>
        <el-dialog title="批量转项" :visible.sync="dialogVisibleTransfer" width="500px" :close-on-click-modal="false">
            <div>
                <el-select v-model="projectId" placeholder="">
                    <el-option v-for="item in projectList" :key="item.id" :label="item.label" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div slot="footer">
                <el-button @click="dialogVisibleTransfer = false">取 消</el-button>
                <el-button type="primary" @click="transferAllEnd()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="批量转发" :visible.sync="dialogVisibleForward" width="500px" :close-on-click-modal="false">
            <div>
                <div style="width:300px;">
                    <el-input v-model="emailValue" placeholder="请输入邮箱"></el-input>
                </div>
                <el-select v-model="receiverList" multiple placeholder="" style="width:300px;margin-top:16px;">
                    <el-option v-for="(item,index) in InsideMembers" :key="index" :label="item.userName" :value="item.email">
                    </el-option>
                </el-select>
            </div>
            <div slot="footer">
                <el-button @click="dialogVisibleForward = false">取 消</el-button>
                <el-button type="primary" @click="forwardAllEnd()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    POST_ADDEDITNEED,
    POST_QUERYNEED,
    GET_DELENEED,
    POST_queryNeedGroupInfo,
    POST_DELENEEDLIST,
    POST_addEditComment,
    POST_transferNeeds,
    POST_topNeed,
    POST_careNeed,
    POST_findInsideMembers,
    POST_sendToEmail,
} from "../request/api";
import { createNeed, selectSearch } from "./parts";
import moment from "moment";

export default {
    data() {
        return {
            cellFontStyle: {
                "text-align": "left",
                "background-color": "ghostwhite",
            },
            cellStyle: {
                "text-align": "left",
            },
            dataTotal: 0,
            Searching: false, //搜索中
            searchForm: {
                projectName: "", //项目名称
                needName: "", //需求名称
                managerList: [], //负责人
                proDevUi: [], //UI工程师
                proDevFront: [], //前端工程师
                moduleNameList: [], //模块
                proVersion: "", //版本号
                tags: [],
                pageNumber: 1,
                pageSize: 5,
            },
            //   项目名称
            projectName: [],
            //   接口名称
            needName: [],
            proManager: [],
            apiList: [],
            tableForm: {},
            checkList: [],
            dataObj: {
                tagList: [],
            },
            dialogVisibleTransfer: false,
            projectList: [],
            projectId: "",
            dialogVisibleForward: false,
            receiverList: [],
            InsideMembers: [],
            emailValue: "",
        };
    },
    created() {
        this.projectName = this.$store.state.projectName;
        this.proManager = this.$store.state.Member;
        if (localStorage.getItem("searchForm")) {
            this.searchForm = JSON.parse(localStorage.getItem("searchForm"));
            localStorage.setItem("searchForm", "");
        }
        this.search();
        POST_queryNeedGroupInfo({
            projectId: this.$store.state.currentProject.id,
        }).then(({ data: res }) => {
            this.dataObj = res.data;
        });
        this.projectList = JSON.parse(sessionStorage.getItem("projectName"));
        this.projectId = this.projectList[0].id;
    },
    methods: {
        changeClass(row) {
            if (row.columnIndex == 0) {
                return "enlarger";
            }
        },
        dateFormat: function (date) {
            if (date.beginTime) {
                return (
                    moment(date.beginTime).format("MM-DD") +
                    "~" +
                    (date.beginTime
                        ? moment(date.overTime).format("MM-DD")
                        : "")
                );
            } else {
                return "";
            }
        },
        openNewWinTab(item) {
            window.open(item.pageUrl);
        },
        comment(item) {
            if (item.needDesc) {
                POST_addEditComment({
                    hmNeedId: item.id,
                    content: item.needDesc,
                }).then(({ data: res }) => {
                    console.log(res);
                    this.$message({
                        message: "评论成功",
                        type: "success",
                    });
                    item.needDesc = "";
                });
            } else {
                this.$message({
                    message: "请输入评论内容",
                });
            }
        },
        cancelComment(item) {
            item.needDesc = "";
        },
        delList() {
            this.$confirm("此操作将永久删除这些需求, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                POST_DELENEEDLIST(this.checkList).then(() => {
                    this.search();
                });
            });
        },
        transferAll() {
            this.dialogVisibleTransfer = true;
        },
        transferAllEnd() {
            let params = {
                needIds: this.checkList,
                projectId: this.projectId,
            };
            POST_transferNeeds(params).then(() => {
                this.dialogVisibleTransfer = false;
                this.search();
            });
        },
        edit(id) {
            this.$router.push("/hmNeedEdit?id=" + id);
            localStorage.setItem("searchForm", JSON.stringify(this.searchForm));
        },
        topNeed(id, type) {
            var txt;
            if (type == 1) {
                txt = "是否将该需求置顶?";
            } else {
                txt = "是否将该需求取消置顶?";
            }
            this.$confirm(txt, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                POST_topNeed({
                    needIds: [id],
                    top: type,
                }).then(() => {
                    this.search();
                });
            });
        },
        careNeed(id, type) {
            var txt;
            if (!type) {
                txt = "是否关注该需求?";
            } else {
                txt = "是否取消关注该需求?";
            }
            this.$confirm(txt, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                POST_careNeed([id]).then(() => {
                    this.search();
                });
            });
        },
        forwardAll() {
            POST_findInsideMembers({
                needIds: this.checkList,
                projectId: this.searchForm.projectId,
            }).then((res) => {
                this.InsideMembers = res.data.data;
                this.dialogVisibleForward = true;
            });
        },
        forwardAllEnd() {
            if (!this.emailValue && this.receiverList.length == 0) {
                this.$message({
                    message: "请选择转发人员或输入邮箱",
                    type: "warning",
                });
                return;
            }
            let receiverList = [];
            this.InsideMembers.forEach((item) => {
                if (this.receiverList.indexOf(item.email) != -1) {
                    receiverList.push(item);
                }
            });
            if (this.emailValue) {
                receiverList.push({
                    email: this.emailValue,
                });
            }
            let params = {
                needIds: this.checkList,
                receiverList: receiverList,
            };
            POST_sendToEmail(params).then(() => {
                this.receiverList = [];
                this.emailValue = [];
                this.dialogVisibleForward = false;
                this.$message({
                    message: "操作成功",
                    type: "success",
                });
            });
        },
        details(id) {
            this.$router.push("/hmNeedDetails?id=" + id);
            localStorage.setItem("searchForm", JSON.stringify(this.searchForm));
        },
        copy(id) {
            this.$router.push("/hmNeedAdd?id=" + id);
        },
        handleSelectionChange(rows) {
            if (rows) {
                this.checkList = [];
                rows.forEach((item) => {
                    this.checkList.push(item.id);
                });
            }
        },
        handleInputConfirm(index, item) {
            let tagNewName = this.apiList[index].tagNewName;
            if (this.apiList[index].tags.indexOf(tagNewName) != -1) {
                this.$message({
                    message: "标签已存在",
                });
                this.apiList[index].inputVisible = false;
                this.apiList[index].tagNewName = "";
                return;
            }
            if (tagNewName) {
                this.apiList[index].tags.push(tagNewName);
                let params = {
                    id: item.id,
                    projectId: item.projectId,
                    projectName: item.projectName,
                    moduleName: item.moduleName,
                    needName: item.needName,
                    manager: item.manager,
                    proVersion: item.proVersion,
                    tags: item.tags,
                    percent: item.percent,
                    brief: item.brief,
                    content: item.content,
                };
                POST_ADDEDITNEED(params).then((res) => {
                    console.log(res);
                });
            }
            this.apiList[index].inputVisible = false;
            this.apiList[index].tagNewName = "";
        },
        handleClose(idx, index, item) {
            this.apiList[index].tags.splice(idx, 1);
            let params = {
                id: item.id,
                projectId: item.projectId,
                projectName: item.projectName,
                moduleName: item.moduleName,
                needName: item.needName,
                manager: item.manager,
                proVersion: item.proVersion,
                tags: item.tags,
                percent: item.percent,
                brief: item.brief,
                content: item.content,
            };
            POST_ADDEDITNEED(params).then((res) => {
                console.log(res);
            });
        },
        setPercent(e, item) {
            console.log(e);
            let params = {
                id: item.id,
                projectId: item.projectId,
                projectName: item.projectName,
                moduleName: item.moduleName,
                needName: item.needName,
                manager: item.manager,
                proVersion: item.proVersion,
                tags: item.tags,
                percent: item.percent,
                brief: item.brief,
                content: item.content,
                beginTime: item.beginTime,
                needPriority: item.needPriority,
            };
            POST_ADDEDITNEED(params).then((res) => {
                console.log(res);
            });
        },
        showInput(index) {
            this.apiList[index].inputVisible = true;
            this.$forceUpdate();
        },
        formatTooltip(val) {
            return val + "%";
        },
        // 重置搜索条件
        resetForm() {
            this.searchForm.needName = "";
            this.searchForm.managerList = [];
            this.searchForm.proDevFront = [];
            this.searchForm.proDevUi = [];
            this.searchForm.tags = [];
            this.searchForm.moduleNameList = [];
            this.searchForm.proVersion = "";
            this.searchForm.beginTime = "";
            this.searchForm.status = "";
            this.searchForm.needPriorityList = [];
            this.search();
        },
        // 打开新建项目对话框
        openNewNeed() {
            // this.$refs.createNeed.openNewNeed(id, copy);
            this.$router.push("/hmNeedAdd");
        },
        // 新建项目
        handleNewNeed(val) {
            POST_ADDEDITNEED(val)
                .then(({ data: res }) => {
                    if (res.resCode == 1) {
                        this.$notify({
                            title: "需求编辑成功",
                            type: "success",
                        });
                    } else {
                        this.$notify.error({
                            title: "需求编辑失败",
                        });
                    }
                    setTimeout(() => {
                        this.search();
                        this.$refs.createNeed.handleClose();
                    }, 400);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 查询搜索
        search(type) {
            if (type == -1) {
                this.searchForm.pageNumber = 1;
            }
            this.Searching = true;
            const waitsearch = setTimeout(() => {
                this.Searching = false;
            }, 600);
            this.searchForm.projectId = this.$store.state.currentProject.id;
            POST_QUERYNEED(this.searchForm)
                .then(({ data: res }) => {
                    if (res.resCode == 1) {
                        this.dataTotal = res.data.total;
                        this.apiList = res.data.list;
                    }
                    waitsearch;
                })
                .catch((err) => {
                    console.log(err);
                    waitsearch;
                });
        },
        //删除项目
        deleteApi(id) {
            this.$confirm("此操作将永久删除该需求, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    GET_DELENEED({ id })
                        .then(({ data: res }) => {
                            if (res.resCode == 1) {
                                this.$notify.success({
                                    title: "成功",
                                    message: "删除成功",
                                });
                                this.search();
                            } else {
                                this.$notify.error({
                                    title: "失败",
                                    message: res.resMsg,
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch(() => {});
        },
        // 分页器
        handleSizeChange(val) {
            this.searchForm.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.searchForm.pageNumber = val;
            this.search();
        },
    },
    computed: {
        name() {
            return this.$store.state.currentProject;
        },
    },
    watch: {
        name(newValue) {
            this.searchForm.projectName = newValue.label;
        },
    },
    components: {
        createNeed,
        selectSearch,
    },
};
</script>

<style lang="less" scoped>
.default {
    width: 200px;

    /deep/ .el-input__inner {
        height: 36px;
        line-height: 36px;
        border-radius: 0;
    }
}

// 搜索按钮
.formBtn {
    height: 36px;
    background: #3285ea;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 36px;
    padding: 0;
    border-radius: 0;
    padding: 0 22px;
}
.btn-reset {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.delectBtn {
    margin-left: 16px;
}
// 外链描述等内容
.longInfo {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tableForm {
    // padding: 0 40rpx;
    padding: 20px 20px 20px 150px;
    .com {
        color: #409eff;
        font-size: 16px;
        line-height: 30px;
        padding-bottom: 20px;
    }
    .bom {
        // display: flex;
        display: -webkit-inline-box;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.0980392156862745);
        padding: 20px;
        .le {
            display: flex;
            align-items: center;
            .label {
                display: flex;
                align-items: center;
                img {
                    margin-left: 10px;
                    width: 30px;
                }
            }
            .value {
                display: flex;
                align-items: center;
                margin-left: 50px;
                img {
                    margin-left: 20px;
                    width: 30px;
                }
            }
        }
        .cen {
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-left: 80px;
            .el-input {
                width: 80px;
            }
            img {
                width: 30px;
                margin-left: 10px;
                cursor: pointer;
            }
        }
        .ri {
            width: 250px;
            padding: 10px 0;
        }
    }
}
.readIng {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgba(245, 108, 108, 1);
    border-radius: 50%;
}
.tagClz {
    display: -webkit-inline-box;
    align-items: center;
}
</style>

<style>
/*  这里是全局表格样式   */
/* .el-table tr, .el-table th, .el-table td{ color: black; font-family:cursive;} */
/* .el-table tr, .el-table th,  */
.need .el-table td {
    color: black;
    font-size: 14px;
}
.enlarger div i {
    font-size: large;
}
.enlarger .el-table__expand-icon {
    height: 50px;
    padding: 15px;
}
.pointer:hover {
    color: #409eff;
}
</style>